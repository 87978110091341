import React from 'react'
import styled from 'styled-components'

// Styles
import { mq } from '../styles/utils/mq'

import SEO from '../components/layout/SEO'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </Wrapper>
  </>
)

const Wrapper = styled.div`
  height: calc(100vh - 347px);

  ${mq.from.M`
    height: calc(100vh - 137px);
  `}

  ${mq.from.L`
    height: calc(100vh - 148px);
  `}

  ${mq.from.XL`
    height: calc(100vh - 162px);
  `}

  ${mq.from.XXL`
    height: calc(100vh - 224px);
  `}
`

export default NotFoundPage
